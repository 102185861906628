@mixin appbar {
    position: fixed;
    // height: 70px;
    width: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 4px 0px rgb(203 221 239 / 30%);
    background-color: #FFF !important;
    padding: 9px 0px;
}


.open {
    @include appbar;
    left: 22%;
    width: 78%;
}

.close {
    @include appbar;
    left: 4%;
    width: 96%;
}

.noUpperCase {
    text-transform: none !important;
    background-color: #f2f8ff!important;
    border: 1px solid rgba(220, 234, 245, 1) !important;
    font-size: 13px !important;
    font-weight:400;
    font-family: 'KiaSignatureRegular' !important; 
    padding: 3px 12px !important;
}

.appBarPageName{
    position: relative;
    color: #05141F;
    font-size: 14px !important;
    font-weight: 400 !important;
    font-family: "KiaSignatureRegular" !important;
    margin-left: 10px !important;
    padding-inline: 10px;
    &::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 3px;
        background-color: #647988;
        bottom: -20px;
        left: 0;
    }
}

.headerUserName{
    font-size: 14px !important;
    font-weight:600 !important;
    margin-top:0px !important;
    font-family: "KiaSignatureRegular" !important;
    line-height: 1.3;
    position:relative;
    padding-right:18px;
    &:after{
        content:'';
        height:12px;
        width:7px;
        display: inline-block;
        background:url(../assets/img/arrow-back.svg) no-repeat 0px 0px;
        background-size: 98%;
        transform: rotate(-90deg);
        position: absolute;
        top: 5px;
        right: 0px;
    }
}
.avatarButton{
    padding:0px !important;
}

@media(max-width: 769px){
    .open{
        width: 100% !important;
        left: 0px !important;
    }
    .mobilelogo{
        display: block !important;
    }
    .mobilenone{
        display: none !important;
    }
}
.mobilelogo{
    display: none;
}

@media(min-width:1600px){
    .appBarPageName{
        margin-left: 18px !important;
    }
}
@media(min-width:1700px){
    .appBarPageName{
        margin-left: 18px !important;
    }
}
@media(min-width:1900px){
    .appBarPageName{
        margin-left: 22px !important;
    }
}
@media(min-width:2000px){
    .appBarPageName{
        margin-left: 34px !important;
    }
}